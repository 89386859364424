import { Pagination } from "flowbite-react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Filter from "../../../../assets/images-svg/Filter";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { REPORTEES_URL } from "../../../reportees/constants/reportees-urls";
import "./ReporteesAttendance.css";
import { IoCloseCircle } from "react-icons/io5";
import Modal from "react-modal";
import moment from "moment";

function ReporteesAttendance() {
  const [attendanceData, setAttendanceData] = useState([]);

  const [singleSelections, setSingleSelections] = useState(null);
  const [cardsPerPageOptions] = useState([10, 15, 30]);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [dates, setDates] = useState(() => calculateDefaultDates());
  const [isChecked, setChecked] = useState(false);

  const [totalElements, setTotalElements] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;

  function calculateDefaultDates() {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - 1);
    return [startDate, currentDate];
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = (e) => {
    const selectedDates = e.value;
    if (selectedDates.length === 1) {
      const endDate = new Date(selectedDates[0]);
      endDate.setDate(endDate.getDate() + 1);
      setDates([selectedDates[0], endDate]);
    } else {
      setDates(selectedDates);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_NAMES}${empId}`;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = REPORTEES_URL.ADMIN_REPORTEES_NAMES;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [recordsPerPage, currentPage]);

  const handleEmployeeSelect = (e) => {
    setSingleSelections(e.value);
  };

  const filterData = (data) => {
    let filteredData = data;

    if (isChecked) {
      filteredData = filteredData.filter((entry) => {
        const durationHours = parseInt(entry.duration.split(":")[0], 10);
        return durationHours < 9;
      });
    }

    return filteredData;
  };

  const employeeNameoptions = attendanceData.map((a) => ({
    label: a?.name,
    value: a?.empId,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (dates && dates.length === 2) {
          const startDate = formatDate(dates[0]);
          const endDate = formatDate(dates[1]);
          const url = `${REPORTEES_URL.GET_ALL_REPORTEES}${empId}`;
          const params = {
            empId,
            startDate,
            endDate,
            pageNumber: currentPage,
            itemsPerPage: recordsPerPage,
            duration: isChecked,
            status: selectStatus,
          };

          if (singleSelections) {
            params.employeeName = singleSelections;
          }

          const response = await axiosService.get(url, params);
          const data = response?.data?.data?.data;
          const totalElements = response?.data?.data?.totalElements;

          setData(filterData(data));
          setTotalElements(totalElements);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [
    dates,
    currentPage,
    recordsPerPage,
    isChecked,
    selectStatus,
    singleSelections,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (dates && dates.length === 2) {
          const startDate = formatDate(dates[0]);
          const endDate = formatDate(dates[1]);
          const url = REPORTEES_URL.GET_ALL_REPORTEES_ADMIN;
          const params = {
            startDate,
            endDate,
            pageNumber: currentPage,
            itemsPerPage: recordsPerPage,
            duration: isChecked,
            status: selectStatus,
          };

          if (singleSelections) {
            params.employeeName = singleSelections;
          }

          const response = await axiosService.get(url, params);
          const data = response?.data?.data?.data;
          const totalElements = response?.data?.data?.totalElements;

          setData(filterData(data));
          setTotalElements(totalElements);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [
    dates,
    currentPage,
    recordsPerPage,
    isChecked,
    selectStatus,
    singleSelections,
  ]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectStatus(selectedOption);
  };

  const options = [
    { value: "ONTIME", label: "On Time" },
    { value: "LATE", label: "Late" },
  ];
  const [imagePreview, setImagePreview] = useState(null);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      width: "550px",
      height: "470px",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      padding: "0",
    },
  };

  return (
    <>
      <div className="w-100 reportees-attendance-list">
        <div className="reportees-attendance-list-container">
          <div className="reportees-attendance-list-banner">
            <div className="reportees-container-fluid">
              {/* Top Filter section */}
              <div className="reportees-row" style={{ display: "flex" }}>
                <div className="col w-100 reportees-Top-column">
                  <div className="reportees-attendance-list-filter-input-container ">
                    <Dropdown
                      id="employee-search"
                      labelKey="employeeFirstName"
                      value={singleSelections}
                      onChange={handleEmployeeSelect}
                      options={employeeNameoptions}
                      placeholder="Employee"
                      filter={true}
                      showClear
                      className=" reportees-custom-dropdown w-full md:w-14rem"
                    />
                  </div>
                  <div className="reportees-attendance-list-date-range">
                    <Calendar
                      id="calendarInput"
                      value={dates}
                      placeholder="Date Range"
                      onChange={handleDateChange}
                      selectionMode="range"
                      readOnlyInput
                      hideOnRangeSelection
                      className="reportees-attendance-list-status-input"
                    ></Calendar>
                    <div className="reportees-attendance-list-date-icon">
                      <Filter />
                    </div>
                  </div>
                  <div className="reportees-status-attendance-list-filter-input-container">
                    <Dropdown
                      id="employee-search"
                      labelKey="days"
                      value={selectStatus}
                      onChange={(e) => handleStatusChange(e.target.value)}
                      options={options}
                      optionLabel="label"
                      showClear
                      placeholder="Status"
                      className="reportees-status-custom-dropdown w-full md:w-14rem"
                    />
                  </div>
                </div>
                <div
                  className="reportees-hrs-bttn"
                  style={{
                    display: "flex",
                  }}
                >
                  <p style={{ marginTop: "5px", marginRight: "10px" }}>
                    Less than 9hrs
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Table Section  */}
              <div className="row">
                <div className="reportees-attendance-list-table-container">
                  <table className="reportees-attendance-list-table">
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Date</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Duration</th>
                        <th>Image In</th>
                        <th>Image Out</th>
                        <th>Status</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e, index) => (
                        <tr key={index}>
                          <td>
                            {e?.employee?.employeeFirstName}{" "}
                            {e?.employee?.employeeLastName}
                          </td>
                          <td>{e.date}</td>
                          <td>
                            {moment
                              .utc(e.checkInTime)
                              .local()
                              .format("hh:mm a")}
                          </td>
                          <td>
                            {e.checkOutTime
                              ? moment
                                  .utc(e.checkOutTime)
                                  .local()
                                  .format("hh:mm a")
                              : "N/A"}
                          </td>
                          <td>{e.duration}</td>
                          <td>
                            {" "}
                            {e?.markInImage && (
                              <img
                                onClick={() => setImagePreview(e?.markInImage)}
                                src={`${e?.markInImage}`}
                                alt="Mark IN Img"
                                className="rounded mx-1"
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </td>
                          <td>
                            {e.markOutImage && (
                              <img
                                onClick={() => setImagePreview(e.markOutImage)}
                                src={`${e.markOutImage}`}
                                alt="Mark Out Img"
                                className="rounded mx-1"
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </td>
                          <td>
                            <div
                              className="attendance-list-table-status"
                              style={{
                                background:
                                  e.attendanceStatus === "LATE"
                                    ? "#E2531933"
                                    : "#3FC28A33",
                                color:
                                  e.attendanceStatus === "LATE"
                                    ? "#E25319"
                                    : "#3FC28A",
                              }}
                            >
                              {e.attendanceStatus}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className="row mt-3"
                  style={{ boxShadow: "0px -2px  #F3EEEA" }}
                >
                  <div className="col w-50 d-flex align-items-center reportees-attendance-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="reportees-attendance-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 reportees-attendance-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            nextLabel={<RightArrow />}
                            previousLabel={<LeftArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={imagePreview}
          style={customStyles}
          onRequestClose={() => setImagePreview(null)}
          shouldCloseOnEsc={true}
        >
          <IoCloseCircle
            onClick={() => setImagePreview(null)}
            color="#000"
            size={"30px"}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
          />

          <img
            src={imagePreview}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Modal>
      </div>
    </>
  );
}

export default ReporteesAttendance;
